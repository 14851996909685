<template>
  <div class="w-100 p-l-30 p-r-30">
    <!-- 11-2 -->

    <div>
      <div class="contents-header">
        <p>
          <router-link :to="{ name: 'Users', query: this.createSearchQuery() }" class="ashen-link">ユーザー管理</router-link>
          <i
              class="fas fa-angle-right form-control-color"></i> ユーザー新規登録
        </p>
      </div>
      <div class="card p-5 text-first">
        <div class="card-body mx-auto">
          <div class="row">
            <div class="col-6">
              <label for="lastName2">姓 名</label>
              <input id="lastName2"
                     v-model="newUser.lastName"
                     class="form-control half-width-input mt-1"
                     placeholder=""
                     type="text"
              />
              <div v-show="errors['lastName']" class="form-error">{{ errors['lastName'] }}</div>
            </div>
            <div class="col-6">
              <label for="firstName2"></label>
              <input id="firstName2"
                     v-model="newUser.firstName"
                     class="form-control half-width-input mt-1"
                     placeholder=""
                     type="text"
              />
              <div v-show="errors['firstName']" class="form-error">{{ errors['firstName'] }}</div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col">
              <label for="exampleInputPassword1">メールアドレス</label>
              <input
                  v-model="newUser.email"
                  class="form-control"
                  placeholder=""
                  type="email"
              />
              <div v-show="errors['email']" class="form-error">{{ errors['email'] }}</div>
            </div>
          </div>
          <div class="row mt-4 dob-section">
            <label class="p-l-0">生年月日</label>
            <div class="d-inline-flex mt-1 p-l-0 p-r-0">
              <div class="input-group custom-select-year parent-block">
                <div class="child-block">年</div>
                <select
                    id="inputGroupSelectYear"
                    v-model="year"
                    class="form-control select_year"
                >
                  <option v-for="year in years" :value="year">
                    {{ year }}
                  </option>
                </select>
              </div>
              <div class="input-group custom-select-year parent-block">
                <div class="child-block">月</div>
                <select
                    id="inputGroupSelectMonth"
                    v-model="month"
                    class="form-control select_month"
                >
                  <option selected value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                </select>
              </div>
              <div class="input-group custom-select-year parent-block m-r-0">
                <div class="child-block">日</div>
                <select
                    id="inputGroupSelectDate"
                    v-model="date"
                    class="form-control select_date"
                >
                  <option selected value="01">01</option>
                  <option value="02">02</option>
                  <option value="03">03</option>
                  <option value="04">04</option>
                  <option value="05">05</option>
                  <option value="06">06</option>
                  <option value="07">07</option>
                  <option value="08">08</option>
                  <option value="09">09</option>
                  <option value="10">10</option>
                  <option value="11">11</option>
                  <option value="12">12</option>
                  <option value="13">13</option>
                  <option value="14">14</option>
                  <option value="15">15</option>
                  <option value="16">16</option>
                  <option value="17">17</option>
                  <option value="18">18</option>
                  <option value="19">19</option>
                  <option value="20">20</option>
                  <option value="21">21</option>
                  <option value="22">22</option>
                  <option value="23">23</option>
                  <option value="24">24</option>
                  <option value="25">25</option>
                  <option value="26">26</option>
                  <option value="27">27</option>
                  <option value="28">28</option>
                  <option value="29">29</option>
                  <option value="30">30</option>
                  <option value="31">31</option>
                </select>
              </div>
            </div>
            <div v-show="errors['birthday']" class="form-error">{{ errors['birthday'] }}</div>
          </div>

          <div class="row mt-4">
            <div class="col">
              <label>JTA管理者</label>
              <select
                  v-model="newUser.isJTA" class="form-control filter-select-long form-select mt-1">
                <option :value="true">JTA管理者</option>
                <option :selected="true" :value="false">なし</option>
              </select>
            </div>
          </div>
          <div class="row mt-4 d-flex justify-content-between">
            <div class="col">
              <button class="btn btn-light bg-white ashen-link text-decoration-none light border-0 w-100"
                      @click="backToUser">キャンセル
              </button>
            </div>
            <div class="col">
              <button class="btn col btn-primary btn-outline-light font-weight-bold w-100" @click="createUser">登録
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import {apiConfig, app, defaults, gender} from '@/constants'
import {mapGetters} from 'vuex'
import Modal from "../components/modal/Modal";
import Common from "jsadminclient/common";

export default {
  name: "UserRegistration",
  components: {Modal},
  props: {
    defaultResponse: {type: Object, default: null}
  },
  data() {
    return {
      app: app,
      apiConfig: apiConfig,
      adminType: defaults.adminType,
      adminTypes: defaults.adminTypes,
      gender: gender,
      loading: true,
      user: '',
      newUser: {
        firstName: "",
        lastName: "",
        email: "",
        birthday: "",
        isJTA: false
      },
      coronaVaccinationStatus: "",
      pcrTestResultStatus: "",
      year: "1990",
      month: "01",
      date: "01",
      errors: []
    }
  },
  mounted() {
    this.apiConfig.headers['X-API-Token'] = this.config.token
    this.initialPageLoadHandler()
  },
  computed: {
    ...mapGetters(['config']),
    years() {
      const year = new Date().getFullYear();
      return Array.from(
          {length: year - 1919},
          (value, index) => 1919 + (index + 1)
      )
    },
  },
  methods: {
    initialPageLoadHandler() {
      $(".tooltip").hide()
    },
    backToUser() {
      this.$router.push({
        name: 'Users', params: {defaultResponse: this.defaultResponse},
        query: this.createSearchQuery()
      })
    },
    getApiConfig() {
      return new AdminApi.ApiConfig(
          this.apiConfig.basePath,
          this.apiConfig.headers
      )
    },
    async createUser() {
      try {
        this.errors = [];
        var api = new AdminApi.JTAUserApi(this.getApiConfig())
        this.newUser.birthday = this.year + "/" + this.month + "/" + this.date
        var result = await api.jtaUsersPostAsync(
            this.newUser
        )
        Common.showToast("ユーザーが登録されました。")
        this.backToUser()
      } catch (e) {
        this.loading = false
        this.errors = Common.getErrorList(e.response, this.errors)
        const isTokenError = Common.isTokenError(e.response)
        if (isTokenError) {
          this.$router.push({name: 'Login'});
        } else {
          Common.handleAPIError(e)
        }
      }
    },
    createSearchQuery() {
      return Common.appendSearchQuery(this.$route.query)
    },
  }
}
</script>

<style lang="scss" scoped>

.breadcrumb-menu {
  margin-top: 0;
  padding-bottom: 22px;
  margin-bottom: 1rem;
  border-bottom: 2px solid #ECE7E8;
}

.btn-file {
  position: relative;
  overflow: hidden;
  border: 1px solid #F2ECF0;
  height: 48px;
  line-height: 30px;
  border-radius: 0px;
}

.button-container {
  padding: 0px 186px;
}

.fa-upload {
  color: #F2ECF0;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 999px;
  text-align: right;
  opacity: 0;
  outline: none;
  background: #fff;
  cursor: pointer;
  display: block;
}

.form-control {
  //background: #F2ECF0;
  border: none;
}

.form-control-admission-number {
  background: #FAF7F9;
  border: none;
  color: #888888;
}

.yes {
  width: 225px;
  height: 44px;
  color: white;
  background-color: #C95D6C;
}

.no {
  width: 225px;
  height: 44px;
  color: #333333;
  border: none;
  background-color: #FFFFFF;
}

/*.input-group{*/
/*    width: 720px;*/
/*}*/

::placeholder {
  color: #333333;
}

.custom-button {
  background: #C95D6C;
  color: #FFFFFF;
  border-radius: 4px;
  width: 160px;
}

.custom-button-outline.btn {
  background: #FFFFFF;
  color: #C95D6C;
  border: 1px solid #C95D6C;
  border-radius: 4px;
  width: 160px;
}

.card-container {
  padding: 7rem !important;
  padding-top: 2rem !important;
}

a {
  color: #333333;
}

.btn-holo {
  border: 1px solid #C95D6C;
  opacity: 1;
}

.dob-section {
  .parent-block {
    .child-block {
      position: absolute;
      top: 0px;
      background: #F8F8F8;
      justify-content: center;
      align-items: center;
      display: flex;
      border: 1px solid #F0E7ED;
      border-radius: 0px 4px 4px 0px;
      height: 100%;
      max-height: 48px;
      width: 2.25rem;
      z-index: 9999;
      right: 0;
      color: #888888;
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }

  .form-control {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }
}

.filter-select {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 32px !important;
}

.filter-select-long {
  background-image: url("../assets/images/down.png");
  background-position: calc(100% - 18px) 50%;
  background-repeat: no-repeat;
  background-size: 11px 11px;
  height: 44px !important;

}

.filter-search-form {
  border: 1px solid #DEE2E6 !important;
  height: 32px !important;
}

.custom-select-year {
  width: 28%;
  margin-right: 23px;

  &:first-child {
    width: 40%;
  }

  &:last-child {
    width: 28%;
    margin-right: 0px !important;
  }
}

.content {
  &.users {
    .custom-button {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }

    .custom-button-outline.btn {
      height: 32px;
      padding-top: 3px;

      img {
        margin-top: 2px;
      }
    }
  }
}
</style>
